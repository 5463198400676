@import '../../base'

.login
  background: #FAFBFD
  padding-top: 120px

  .ant-form-item-explain-error
    margin: 4px 0

  .login-logo
    @include flex
    justify-content: center
    top: 120px
    position: absolute
    left: 50%
    transform: translateX(-50%)

  .login-error-message
    position: absolute
    font-size: 14px !important
    background: rgba(235, 68, 60, .8)
    bottom: 20px
    color: #fff
    left: 50%
    transform: translateX(-50%)
    padding: 12px 16px
    border-radius: 4px
    box-shadow: 0 0 8px rgba(0,0,0,.3)

  .ant-layout-footer
    @include flex
    justify-content: center
    height: 78px
    min-height: 78px
    border-top: 10px solid var(--primaryColor)
    background: var(--login_footerBg)
    padding: 10px
    color: #fff
    font-size: 14px
    text-transform: uppercase

  .ant-layout-content
    position: relative
    height: 100%
    justify-content: center
    @include flex
    flex-direction: column
    &:after
      content: ""
      position: absolute
      bottom: 0
      right: 0
      width: 330px
      height: 400px
      background-color: var(--login_patternColor)
      mask-image: url("../../icons/login/pattern.svg")

    form
      display: flex
      flex-direction: column
      width: 420px

      :has(.login-code)
        .login-title
          text-align: left

      .login-title
        color: var(--login_color)
        @include bold
        text-align: center
        font-size: 24px!important

      .login-hint
        font-size: 14px !important
        padding-bottom: 10px

      .login-countdown
        font-size: 14px !important
        margin-bottom: 30px

      .ant-form-item.login-code
        margin-bottom: 10px

      .ant-form-item
        margin-bottom: 30px
        .ant-form-item-control-input
          min-height: auto
          .ant-input
            height: 40px
            border-radius: 4px
            font-size: 14px!important

        .ant-col
          max-width: 100%
          min-height: 40px
          .ant-input-affix-wrapper
            height: 40px
            border-radius: 4px
            input
              height: 100%

          .ant-input-password
            padding-left: 38px
            &:before
              content: ""
              width: 18px
              height: 20px
              mask-image: url("../../icons/login/password.svg")
              background-color: var(--login_color)
              position: absolute
              left: 10px
              top: 50%
              transform: translateY(-50%)
              visibility: visible

            .ant-input-suffix svg
              width: 18px
              height: auto

      .login-email
        .ant-form-item-control-input-content:before
          content: ""
          position: absolute
          width: 18px
          height: 14px
          mask-image: url("../../icons/login/email.svg")
          background-color: var(--login_color)
          position: absolute
          left: 10px
          top: 50%
          transform: translateY(-50%)
          z-index: 1
        .ant-input
          padding-left: 38px

      .login-code
        margin-bottom: 10px
        .ant-form-item-control-input-content:before
          content: ""
          position: absolute
          width: 18px
          height: 22px
          background: url("../../icons/login/2fa.svg") no-repeat
          position: absolute
          left: 10px
          top: 50%
          transform: translateY(-50%)
          z-index: 1
        .ant-input
          padding-left: 38px

      .login-btn
        width: 100px
        height: 38px
        text-transform: uppercase
        margin: 0 auto 20%
        border-radius: 4px
        font-size: 14px!important
        @include bold
        background: var(--primaryColor)
        color: var(--btn_color)!important
        &:hover
          background: var(--btn_primaryHoverBg)
        &:active
          background: var(--btn_primaryActiveBg)
